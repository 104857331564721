import React, { useContext } from 'react';
import { Nav, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { BoxArrowRight, ClipboardCheck, Grid, People, Basket3, List, Person } from 'react-bootstrap-icons'; // Agregar el icono de Person
import logo from '../img/logo-gallito-1.png'; // Logo superior
import { AuthContext } from '../authContext';
import Swal from 'sweetalert2';

const Sidebar = () => {
  const { user, logout } = useContext(AuthContext); 

  const handleLogout = () => {
    logout();
    Swal.fire({
      icon: 'info',
      title: 'Sesión cerrada',
      text: 'Has cerrado sesión exitosamente',
    }).then(() => {
      window.location.href = '/login';
    });
  };

  const menuItems = [
    { path: '/checkin', icon: <ClipboardCheck className="sidebar-icon" />, label: 'Check-In', show: user && (user.rol === 'admin' || user.rol === 'recepcion') },
    { path: '/admin', icon: <People className="sidebar-icon" />, label: 'Comandero', show: user && (user.rol === 'admin' || user.rol === 'comandero') },
    { path: '/mesas', icon: <Grid className="sidebar-icon" />, label: 'Mesas', show: user && user.rol === 'admin' },
    { path: '/comidas', icon: <Basket3 className="sidebar-icon" />, label: 'Comidas', show: user && user.rol === 'admin' },
    { path: '/categorias', icon: <List className="sidebar-icon" />, label: 'Categorías', show: user && user.rol === 'admin' },
    { path: '/usuarios', icon: <Person className="sidebar-icon" />, label: 'Usuarios', show: user && user.rol === 'admin' },
    { path: '/logout', icon: <BoxArrowRight className="sidebar-icon" />, label: 'Logout', action: handleLogout, show: true }, // Siempre mostrar el logout
  ];

  return (
    <Nav className="d-flex flex-column align-items-center bg-custom text-white vh-100">
      <div className="my-4">
        <img src={logo} alt="El Gallito Mañanero" style={{ width: '40px' }} />
      </div>
      {menuItems
        .filter(item => item.show) // Filtrar solo los elementos que se deben mostrar
        .map((item, index) => (
          <OverlayTrigger
            key={index}
            placement="right"
            overlay={<Tooltip id={`tooltip-${index}`}>{item.label}</Tooltip>}
          >
            <Nav.Link
              as={Link}
              to={item.path}
              className="text-white py-3"
              onClick={item.action ? item.action : null} // Agrega la acción de logout
            >
              {item.icon}
            </Nav.Link>
          </OverlayTrigger>
        ))}
    </Nav>
  );
};

export default Sidebar;
