import React, { useState, useEffect, useContext } from "react";
import Swal from "sweetalert2";
import { Modal, Button } from "react-bootstrap";
import API from "../axiosConfig";
import { AuthContext } from "../authContext";
import InputMask from "react-input-mask";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./CheckInForm.css";
import "bootstrap-icons/font/bootstrap-icons.css";

const CheckInForm = () => {
  const { user } = useContext(AuthContext);
  const sucursal_id = user.sucursal.id;

  const [formData, setFormData] = useState({
    nombre: "",
    celular: "",
    fechaCumpleanos: new Date("1998-12-31"),
    cantidadPersonas: 1,
    numeroMesa: [],
  });
  const [clientesSugeridos, setClientesSugeridos] = useState([]);
  const [clienteSeleccionado, setClienteSeleccionado] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [pendingList, setPendingList] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [showSelect, setShowSelect] = useState(false);
  const [touchStartX, setTouchStartX] = useState(null);
  const [itemDeleting, setItemDeleting] = useState(null);
  const [mesas, setMesas] = useState([]);
  const [viewMode, setViewMode] = useState("disponibles");

  useEffect(() => {
    const fetchMesas = async () => {
      const sucursalId = user?.sucursal?.id; // Usa el id de la sucursal del usuario
      if (sucursalId) {
        try {
          const response = await API.get(`/mesas?sucursal_id=${sucursalId}`);
          setMesas(response.data);
        } catch (error) {
          console.error("Error al cargar las mesas:", error);
        }
      } else {
        console.error("Sucursal ID no encontrado.");
      }
    };
  
    fetchMesas();
  }, [user?.sucursal?.id]); // Dependencia en el ID de la sucursal
  
  
  const handleChange = async (e) => {
    const { name, value } = e.target;

    if (name === "nombre") {
      setFormData({ ...formData, [name]: value.toUpperCase() });
      if (value.length >= 2) {
        try {
          const response = await API.get("/clientes", {
            params: { nombre: value },
          });
          setClientesSugeridos(response.data);

          const clienteEncontrado = response.data.find(
            (cliente) => cliente.nombre === value.toUpperCase()
          );
          if (!clienteEncontrado) {
            setClienteSeleccionado(null);
          }
        } catch (error) {
          console.error(error);
        }
      } else {
        setClientesSugeridos([]);
        setClienteSeleccionado(null);
      }
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleClienteSelect = (cliente) => {
    setFormData({
      nombre: cliente.nombre,
      celular: cliente.celular,
      fechaCumpleanos: new Date(cliente.fecha_cumpleanos),
      cantidadPersonas: formData.cantidadPersonas,
      numeroMesa: formData.numeroMesa,
    });
    setClienteSeleccionado(cliente);
    setClientesSugeridos([]);
  };

  const handleSelectChange = (e) => {
    setFormData({ ...formData, cantidadPersonas: parseInt(e.target.value) });
    setShowSelect(false);
  };

  const validateForm = () => {
    const { nombre, celular, fechaCumpleanos, cantidadPersonas, numeroMesa } =
      formData;
    const today = new Date().toISOString().split("T")[0];

    if (!nombre) {
      return "El nombre del cliente es obligatorio.";
    }

    if (!celular) {
      return "El número de celular es obligatorio.";
    }

    if (!fechaCumpleanos) {
      return "La fecha de cumpleaños es obligatoria.";
    }

    if (new Date(fechaCumpleanos) > new Date(today)) {
      return "La fecha de cumpleaños no puede ser en el futuro.";
    }

    if (!cantidadPersonas || cantidadPersonas < 1) {
      return "La cantidad de personas es obligatoria y debe ser al menos 1.";
    }

    if (!numeroMesa.length) {
      return "El número de mesa es obligatorio.";
    }

    return null;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationError = validateForm();
    if (validationError) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: validationError,
      });
      return;
    }

    try {
      let cliente_id;

      // Si el cliente está seleccionado, usa su ID, si no, crea un nuevo cliente
      if (clienteSeleccionado) {
        cliente_id = clienteSeleccionado.id;
      } else {
        const clienteResponse = await API.post("/clientes", {
          nombre: formData.nombre,
          celular: formData.celular,
          fecha_cumpleanos: formData.fechaCumpleanos
            .toISOString()
            .split("T")[0],
        });
        cliente_id = clienteResponse.data.id;
      }

      // Ahora registra la visita usando el cliente_id
      await API.post("/visitas", {
        cliente_id,
        sucursal_id,
        cantidad_personas: formData.cantidadPersonas,
        numero_mesa: formData.numeroMesa.join(", "),
        fecha_visita: new Date(),
      });

      // Actualiza el estado de las mesas a ocupadas
      for (const mesaId of formData.numeroMesa) {
        await API.put(`/mesas?id=${mesaId}&action=updateEstado`, { esta_ocupada: true });

      }

      Swal.fire({
        icon: "success",
        title: "Visita registrada",
        text: "La visita ha sido registrada con éxito.",
      });

      setFormData({
        nombre: "",
        celular: "",
        fechaCumpleanos: new Date("1998-12-31"),
        cantidadPersonas: 1,
        numeroMesa: [],
      });
      setClienteSeleccionado(null);

      // Refresca las mesas después de registrar la visita
      const response = await API.get(`/mesas?sucursal_id=${sucursal_id}`);
      setMesas(response.data);
    } catch (error) {
      console.error(error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: error.response
          ? error.response.data.message
          : "Error al registrar la visita",
      });
    }
  };

  const handleMesaClick = (mesa) => {
    setFormData((prev) => {
      const newMesas = prev.numeroMesa.includes(mesa.id)
        ? prev.numeroMesa.filter((num) => num !== mesa.id)
        : [...prev.numeroMesa, mesa.id];
      return { ...prev, numeroMesa: newMesas };
    });
  };

  const handlePendingClick = async () => {
    const validationError = validateForm();
    if (validationError) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Completa todos los campos antes de agregar una mesa pendiente.",
      });
      return;
    }

    const pendingItem = {
      id: Date.now(),
      nombre: formData.nombre,
      telefono: formData.celular,
      personas: formData.cantidadPersonas,
      mesa: formData.numeroMesa,
      cumpleanos: formData.fechaCumpleanos.toISOString().split("T")[0],
      cliente_id: clienteSeleccionado ? clienteSeleccionado.id : null,
    };

    setPendingList((prevPendingList) => {
      const updatedList = [...prevPendingList, pendingItem];
      localStorage.setItem("pendingList", JSON.stringify(updatedList)); // Guardar en localStorage
      return updatedList;
    });

    // Actualizar el estado de las mesas seleccionadas a ocupadas
    for (const mesaId of formData.numeroMesa) {
      await API.put(`/mesas?id=${mesaId}`, { esta_ocupada: true });
    }

    setFormData({
      nombre: "",
      celular: "",
      fechaCumpleanos: new Date("1998-12-31"),
      cantidadPersonas: 1,
      numeroMesa: [],
    });
    setClienteSeleccionado(null);

    // Refrescar las mesas después de agregar a pendientes
    const response = await API.get(`/mesas?sucursal_id=${sucursal_id}`);
    setMesas(response.data);
  };

  const handleTouchStart = (e, id) => {
    setTouchStartX(e.touches[0].clientX);
    setItemDeleting(id);
  };

  const handleTouchMove = (e, id) => {
    if (!touchStartX) return;
    const touchEndX = e.touches[0].clientX;
    const deltaX = touchEndX - touchStartX;

    if (deltaX > 100) {
      handlePendingDelete(id);
    }
  };

  const handleTouchEnd = () => {
    setTouchStartX(null);
    setItemDeleting(null);
  };

  const handlePendingDelete = (id) => {
    setPendingList((prevPendingList) => {
      const updatedList = prevPendingList.filter((item) => item.id !== id);
      localStorage.setItem("pendingList", JSON.stringify(updatedList)); // Actualizar localStorage
      return updatedList;
    });
  };

  const handlePendingItemClick = async (item) => {
    try {
      let cliente_id = item.cliente_id;

      // Si el cliente no está registrado, lo creamos
      if (!cliente_id) {
        const clienteResponse = await API.post("/clientes", {
          nombre: item.nombre,
          celular: item.telefono,
          fecha_cumpleanos: item.cumpleanos,
        });
        cliente_id = clienteResponse.data.id;
      }

      // Ahora que tenemos el cliente_id, lo usamos para la visita
      setFormData({
        nombre: item.nombre,
        celular: item.telefono,
        fechaCumpleanos: new Date(item.cumpleanos),
        cantidadPersonas: item.personas,
        numeroMesa: item.mesa,
      });
      setClienteSeleccionado({ id: cliente_id });

      // Removemos el item de la lista de pendientes
      handlePendingDelete(item.id);
    } catch (error) {
      console.error("Error al manejar el cliente pendiente:", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Error al manejar el cliente pendiente",
      });
    }
  };

  const openMesaModal = () => {
    Swal.fire({
      title: "¿Qué deseas ver?",
      text: "Elige una opción:",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Mesas Disponibles",
      cancelButtonText: "Mesas Ocupadas",
    }).then((result) => {
      if (result.isConfirmed) {
        setViewMode("disponibles");
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        setViewMode("ocupadas");
      }
      setModalOpen(true);
    });
  };

  const handleLiberarMesa = async (mesa) => {
    const confirm = await Swal.fire({
      title: "¿Estás seguro?",
      text: `¿Deseas liberar la mesa ${mesa.numero_mesa}?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Sí, liberar",
      cancelButtonText: "Cancelar",
    });

    if (confirm.isConfirmed) {
      try {
     await API.put(`/mesas?id=${mesa.id}&action=updateEstado`, { esta_ocupada: true });
        setMesas((prev) =>
          prev.map((m) =>
            m.id === mesa.id ? { ...m, esta_ocupada: false } : m
          )
        );
        Swal.fire("Liberada", "La mesa ha sido liberada.", "success");
      } catch (error) {
        console.error("Error al liberar la mesa:", error);
        Swal.fire("Error", "Hubo un problema al liberar la mesa.", "error");
      }
    }
  };

  const filteredPendingList = pendingList.filter((pendingItem) =>
    pendingItem.nombre.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="container mx-auto p-4">
      <div className="row justify-content-center">
        <div className="col-12 col-lg-6 mb-3">
          <div className="card shadow-lg p-5">
            <h3 className="text-center mb-4">EL GALLITO MAÑANERO</h3>
            <form onSubmit={handleSubmit}>
              <div className="row mb-3">
                <div className="col-12">
                  <label htmlFor="nombre" className="form-label">
               Cliente
                  </label>
                  <input
                    id="nombre"
                    name="nombre"
                    type="text"
                    value={formData.nombre}
                    onChange={handleChange}
                    className="form-control"
                    required
                    autoComplete="off"
                  />
                  {clientesSugeridos.length > 0 && (
                    <ul className="list-group position-absolute w-100 shadow-sm rounded mt-1 custom-dropdown">
                      {clientesSugeridos.map((cliente) => (
                        <li
                          key={cliente.id}
                          className="list-group-item list-group-item-action custom-item"
                          onClick={() => handleClienteSelect(cliente)}
                          style={{ cursor: "pointer" }}
                        >
                          {cliente.nombre}
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-12 col-md-6">
                  <label htmlFor="celular" className="form-label">
                   Numero Celular
                  </label>
                  <InputMask
                    id="celular"
                    name="celular"
                    mask="(52)999-999-9999"
                    value={formData.celular}
                    onChange={handleChange}
                    className="form-control"
                    required
                  />
                </div>
                <div className="col-12 col-md-6">
                  <label htmlFor="fechaCumpleanos" className="form-label">
                   Cumpleaños
                  </label>
                  <DatePicker
                    id="fechaCumpleanos"
                    selected={formData.fechaCumpleanos}
                    onChange={(date) =>
                      setFormData({ ...formData, fechaCumpleanos: date })
                    }
                    className="form-control"
                    showYearDropdown
                    dateFormat="MM/dd/yyyy"
                    yearDropdownItemNumber={15}
                    scrollableYearDropdown
                    required
                  />
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-12 col-md-6">
                  <label htmlFor="cantidadPersonas" className="form-label">
                   Personas
                  </label>
                  <div className="input-group">
                    <input
                      id="cantidadPersonas"
                      name="cantidadPersonas"
                      type="number"
                      value={formData.cantidadPersonas}
                      onChange={handleChange}
                      className="form-control"
                      min="1"
                      max="30"
                      required
                    />
                    <Button
                      variant="outline-secondary"
                      onClick={() => setShowSelect(!showSelect)}
                    >
                      <i className="bi bi-caret-down-fill"></i>
                    </Button>
                  </div>
                  {showSelect && (
                    <select
                      id="cantidadPersonasSelect"
                      className="form-select mt-1 custom-select"
                      onChange={handleSelectChange}
                      size="10"
                      style={{
                        position: "absolute",
                        zIndex: 1050,
                        width: "100%",
                        maxHeight: "100px", // Limitar la altura del select
                        overflowY: "auto", // Añadir scroll si el contenido es demasiado grande
                      }}
                    >
                      {Array.from({ length: 30 }, (_, i) => (
                        <option key={i + 1} value={i + 1}>
                          {i + 1}
                        </option>
                      ))}
                    </select>
                  )}
                </div>
                <div className="col-12 col-md-6">
                  <label htmlFor="numeroMesa" className="form-label">
                    Mesa
                  </label>
                  <div className="input-group">
                    <input
                      id="numeroMesa"
                      name="numeroMesa"
                      type="text"
                      value={formData.numeroMesa.join(", ")}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          numeroMesa: [e.target.value],
                        })
                      }
                      className="form-control"
                      required
                    />
                    <Button
                      variant="outline-secondary"
                      onClick={openMesaModal}
                    >
                      <i className="bi bi-grid-fill"></i>
                    </Button>
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-between flex-wrap">
                <button
                  type="submit"
                  className="btn btn-success btn-lg flex-grow-1 mx-1 my-1"
                >
                  Guardar
                </button>
                <button
                  type="button"
                  className="btn btn-danger btn-lg flex-grow-1 mx-1 my-1"
                  onClick={() => {
                    setFormData({
                      nombre: "",
                      celular: "",
                      fechaCumpleanos: new Date("1998-12-31"),
                      cantidadPersonas: 1,
                      numeroMesa: [],
                    });
                    setClienteSeleccionado(null);
                  }}
                >
                  Cancelar
                </button>
                <button
                  type="button"
                  className="btn btn-primary btn-lg flex-grow-1 mx-1 my-1"
                  onClick={handlePendingClick}
                >
                  Pendiente
                </button>
              </div>
            </form>
          </div>
        </div>
        <div className="col-12 col-lg-6">
          <div className="card shadow-lg p-5" style={{ height: "100%" }}>
            <h4 className="text-center mb-4">Lista de Espera</h4>
            <input
              type="text"
              className="form-control mb-3"
              placeholder="Buscar Cliente..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <ul
              className="list-group"
              style={{
                maxHeight: "450px", // Ajuste de la altura para que coincida con el diseño
                overflowY: "auto",
              }}
            >
              {filteredPendingList.map((pendingItem) => (
                <li
                  key={pendingItem.id}
                  className={`list-group-item d-flex justify-content-between align-items-center ${
                    itemDeleting === pendingItem.id ? "deleting" : ""
                  }`}
                  style={{
                    position: "relative",
                    overflow: "hidden",
                    transition: "transform 0.3s ease-out, opacity 0.3s ease-out",
                    cursor: "pointer",
                  }}
                  onTouchStart={(e) => handleTouchStart(e, pendingItem.id)}
                  onTouchMove={(e) => handleTouchMove(e, pendingItem.id)}
                  onTouchEnd={(e) => handleTouchEnd(e, pendingItem.id)}
                  onClick={() => handlePendingItemClick(pendingItem)}
                >
                  <div>
                    <span>Nombre: {pendingItem.nombre}</span>
                    <br />
                    <span>Teléfono: {pendingItem.telefono}</span>
                    <br />
                    <span>Personas: {pendingItem.personas}</span>
                    <br />
                    <span>Mesa: {pendingItem.mesa.join(", ")}</span>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>

      {/* Modal para seleccionar mesas */}
      <Modal
        show={modalOpen}
        onHide={() => setModalOpen(false)}
        centered
        size="lg" // Aquí se establece el tamaño del modal a "lg" (grande)
        dialogClassName="modal-90w" // Personaliza el tamaño a 90% del ancho de la ventana (opcional)
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {viewMode === "disponibles"
              ? "Seleccione las Mesas Disponibles"
              : "Libere las Mesas Ocupadas"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ maxHeight: "500px", overflowY: "auto" }}>
          <div className="mesa-grid">
            {mesas.map((mesa) => (
              <button
                key={mesa.id}
                className={`mesa-item ${
                  formData.numeroMesa.includes(mesa.id) ? "selected" : ""
                } ${mesa.esta_ocupada ? "ocupada" : "disponible"}`}
                onClick={() =>
                  viewMode === "disponibles"
                    ? handleMesaClick(mesa)
                    : handleLiberarMesa(mesa)
                }
                disabled={
                  (viewMode === "disponibles" && mesa.esta_ocupada) ||
                  (viewMode === "ocupadas" && !mesa.esta_ocupada)
                }
                style={{
                  backgroundColor: mesa.esta_ocupada ? "#ff4d4d" : "#4caf50",
                  color: "#fff",
                  borderRadius: "10px",
                  padding: "15px",
                  margin: "10px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  fontSize: "20px",
                  fontWeight: "bold",
                  width: "100px",
                  height: "100px",
                  cursor:
                    viewMode === "disponibles" && mesa.esta_ocupada
                      ? "not-allowed"
                      : "pointer",
                  boxShadow: mesa.esta_ocupada
                    ? "0 0 10px rgba(255,0,0,0.5)"
                    : "0 0 10px rgba(0,255,0,0.5)",
                }}
              >
                <i
                  className={`bi ${
                    mesa.esta_ocupada
                      ? "bi-x-circle-fill"
                      : "bi-check-circle-fill"
                  }`}
                  style={{ marginRight: "8px", fontSize: "24px" }}
                ></i>
                {mesa.numero_mesa}
              </button>
            ))}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setModalOpen(false)}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default CheckInForm;
