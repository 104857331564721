import React, { useState, useContext } from 'react';
import Swal from 'sweetalert2';
import { AuthContext } from '../authContext';
import { useNavigate } from 'react-router-dom';
import API from '../axiosConfig';

const API_URL = '/usuarios?action=login';

const LoginForm = () => {
  const [formData, setFormData] = useState({
    nombre_usuario: '',
    contraseña: '',
  });
  const { login } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await API.post(API_URL, formData);
      const { token, id, nombre_usuario, sucursal, rol } = response.data; // Asegúrate de que el rol esté presente en la respuesta
      login(token, id, nombre_usuario, sucursal, rol); // Incluye el rol en la llamada a login
      Swal.fire({
        icon: 'success',
        title: 'Inicio de sesión exitoso',
        text: '¡Bienvenido!',
      }).then(() => {
        // Redirigir según el rol
        if (rol === 'admin') {
          navigate('/checkin');
        } else if (rol === 'comandero') {
          navigate('/admin'); // Puedes cambiar esta ruta si tienes una ruta específica para comanderos
        } else if (rol === 'recepcion') {
          navigate('/checkin');
        } else {
          navigate('/login'); // Redirigir al login si el rol no es reconocido
        }
      });
    } catch (error) {
      const errorMessage = error.response
        ? error.response.data.message
        : 'Error al iniciar sesión';
      Swal.fire({
        icon: 'error',
        title: 'Error de inicio de sesión',
        text: errorMessage,
      });
    }
  };
  

  return (
    <div className="login-container d-flex align-items-center justify-content-center">
      <div className="login-box bg-white p-5 rounded shadow-lg">
        <div className="text-center mb-4 d-flex align-items-center justify-content-center">
          <img 
            src={require('../img/logo-gallito-1.png')} 
            alt="El Gallito Mañanero" 
            className="mr-3" 
            style={{ height: '60px', width: '60px' }} 
          />
          <h1 className="h4 mb-0 font-weight-bold text-uppercase">El Gallito Mañanero</h1>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="mb-3">
            <label htmlFor="nombre_usuario" className="form-label">Usuario</label>
            <input
              id="nombre_usuario"
              name="nombre_usuario"
              type="text"
              value={formData.nombre_usuario}
              onChange={handleChange}
              className="form-control"
              required
            />
          </div>
          <div className="mb-3">
            <label htmlFor="contraseña" className="form-label">Contraseña</label>
            <input
              id="contraseña"
              name="contraseña"
              type="password"
              value={formData.contraseña}
              onChange={handleChange}
              className="form-control"
              required
            />
          </div>
          <div className="d-grid">
            <button type="submit" className="btn btn-warning rounded-pill text-uppercase">Iniciar</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default LoginForm;
